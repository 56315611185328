import styled from "styled-components";
import { Colors } from "../util/Colors";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
const errorImg = require("../assets/images/error_msg.gif");
const noImg = require("../assets/images/user.png");
import {
   TableResponsive,
   CardHeader,
   CardTitle,
   CardTable,
   CardTableThead,
   CardTr,
   CardTh,
   CardTableBody,
   CardTd,
   NoContentText,
   AssignImage,
} from "../pages/DeviceView";
import { useQuery } from "@apollo/client";
import GET_TESTS_DETAIL from "../graphql/queries/getAllTests.query";
import GET_DEVICES from "../graphql/queries/getDevices.query";
import Icon, { Icons } from "../components/elements/Icon";
import moment from "moment";
import {
   BottomLink,
   ButtonWrapper,
   CardText,
   CardWrapper,
   LinkText,
   PanelBody,
   PanelHeader,
   PanelTitle,
   Title,
   TitleWrapper,
   Value,
   ValueWrapper,
} from "../pages/ViewAccount";
import routePaths from "../config/routepaths.config";
import { useNavigate } from "react-router-dom";
import { GaizeState } from "../redux/store";
import { useSelector } from "react-redux";
import { ErrorImage } from "./SuperAdmin";
import LoaderContainer from "../components/elements/LoaderContainer";
import Loader, { LoaderSizes } from "../components/elements/Loader";
import { Roles } from "../models/enums/RolesEnum";
import BaseModal, { ModalList } from "../components/modal/BaseModal";
import GET_PLAN_DATA from "../graphql/queries/getPlan.query";

export const MainWrapper = styled.div`
   padding: 40px 0;
   margin: 64px 0 0 0;
`;
export const Container = styled.div`
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
   }
`;
export const DeviceContainer = styled.div`
   width: 100%;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
`;
export const TestContainer = styled.div`
   width: 100%;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
`;
export const SubContainer = styled.div`
   display: flex;
   @media (max-width: 767px) {
      flex-direction: column;
   }
`;
export const MoveLink = styled.a`
   background: linear-gradient(127.15deg, #386EE2 17.06%, #6F24BC 93.36%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 700;
   font-size: 15px;name
   line-height: 32px;
   letter-spacing: -0.165px;
   cursor: pointer;
`;
// export const PanelCard = styled.div`
//    background: #ffffff;
//    border-radius: 4px;
//    margin: 38px 0 20px 17px;
//    height: -webkit-max-content;
//    height: -moz-max-content;
//    height: max-content;
//    width: 32%;
//    @media (max-width: 767px) {
//       width: 100%;
//       margin: 0 0 20px 0;
//    }
// `;
export const LeftWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const Home = () => {
   const navigate = useNavigate();
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const userRole = userData?.role;
   const clientUser = localStorage.getItem("clientUser");
   const [stripeDetails, setStripeDetails] = useState(false);
   const nextDate =
      userData?.organization?.billing?.nextPaymentDate &&
      moment(
         parseInt(userData?.organization?.billing?.nextPaymentDate, 10)
      ).format("MMM DD, YYYY (h:mm A)");
   const cardDetails = userData?.organization?.billing?.cardDetails;
   const paidThrough = userData?.organization?.billing?.paidThrough;
   // State
   const [testsDetail, setTestsDetail] = useState([]);
   const [devicesDetail, setDevicesDetail] = useState([]);
   const [isDataLoading, setIsDataLoading] = useState(true);
   // Action
   const onTestDetail = (item, i) => {
      const testId = item._id;
      navigate(`/tests/${testId}`, { state: item });
   };

   const addStripeDetails = () => {
      setStripeDetails(true);
   };

   /** GraphQL */
   const { data } = useQuery(GET_TESTS_DETAIL, {
      variables: {
         startDate: "",
         endDate: "",
      },
      fetchPolicy: "network-only",
      onCompleted(res) {
         setTestsDetail(res?.getAllTests);
         setIsDataLoading(false);
      },
   });

   const { data: deviceData } = useQuery(GET_DEVICES, {
      fetchPolicy: "network-only",
      onCompleted(res) {
         setDevicesDetail(
            res.getDevices.filter(
               (item) =>
                  item.assignedUserId === null || item.assignedUserId === ""
            )
         );
         setIsDataLoading(false);
      },
   });

   // GET_PLAN_DATA
   const { data: getPlanData, loading } = useQuery(GET_PLAN_DATA, {
      fetchPolicy: "network-only",
      variables: {
         planId: userData?.organization?.billing?.planId,
      },
   });
   const planName = getPlanData?.plan?.name;

   return (
      <>
         {userRole !== Roles.User ? (
            <MainWrapper>
               <BaseModal
                  open={stripeDetails}
                  modalType={ModalList.StripeDetailsModal}
                  onClose={setStripeDetails}
                  onCompleted={null}
               />
               {isDataLoading ? (
                  <LoaderContainer>
                     <Loader color={Colors.blue} size={LoaderSizes.Medium} />
                  </LoaderContainer>
               ) : (
                  <Container>
                     {/* <SubContainer> */}
                        <TestContainer>
                           <CardHeader>
                              <LeftWrapper>
                                 <CardTitle>Recent Tests</CardTitle>
                              </LeftWrapper>
                              <MoveLink href={routePaths.TESTS}>
                                 {data?.getAllTests?.length > 10 && "All Tests"}
                              </MoveLink>
                           </CardHeader>
                           <TableResponsive>
                              <CardTable>
                                 <CardTableThead>
                                    <CardTr>
                                       <CardTh>Test ID</CardTh>
                                       <CardTh>Test Administrator</CardTh>
                                       <CardTh>Test Date/Time</CardTh>
                                       <CardTh>Assigned ID</CardTh>
                                    </CardTr>
                                 </CardTableThead>
                                 <CardTableBody>
                                    {testsDetail &&
                                       testsDetail
                                          .slice(0, 10)
                                          .map((item: any, i) => {
                                             const deviceUserName =
                                                item.user?.firstName ||
                                                item.user?.lastName
                                                   ? `${item.user?.firstName}` +
                                                     " " +
                                                     `${item.user?.lastName}`
                                                   : "-";
                                             const date =
                                                item.meta?.createdDate &&
                                                moment(
                                                   parseInt(
                                                      item.meta
                                                         ?.createdDate as any,
                                                      10
                                                   )
                                                ).format(
                                                   "MMM DD, YYYY (h:mm A)"
                                                );
                                             return (
                                                <CardTr key={i}>
                                                   <CardTd>{item._id}</CardTd>
                                                   <CardTd>
                                                      {deviceUserName}
                                                   </CardTd>
                                                   <CardTd>{date}</CardTd>
                                                   <CardTd>
                                                      {item.caseNumber} &nbsp;
                                                      &nbsp; &nbsp; &nbsp;{" "}
                                                      <span className="editIcon">
                                                         <Icon
                                                            icon={Icons.Eye}
                                                            size={16}
                                                            color={
                                                               Colors.darkGray
                                                            }
                                                            onClick={() =>
                                                               onTestDetail(
                                                                  item,
                                                                  i
                                                               )
                                                            }
                                                         />{" "}
                                                      </span>
                                                   </CardTd>
                                                </CardTr>
                                             );
                                          })}
                                 </CardTableBody>
                              </CardTable>
                              {testsDetail?.length === 0 && !isDataLoading ? (
                                 <NoContentText>
                                    No Records Found{" "}
                                 </NoContentText>
                              ) : (
                                 false
                              )}
                           </TableResponsive>
                        </TestContainer>
                        {/* 
                        <PanelCard>
                           <PanelHeader>
                              <PanelTitle>Billing</PanelTitle>
                           </PanelHeader>
                           <PanelBody>
                              <CardText>
                                 {cardDetails?.brand
                                    ? planName
                                    : "Your Account is Current"}
                              </CardText>
                              <CardWrapper>
                                 <TitleWrapper>
                                    <Title>Payment Method:</Title>
                                    <Title>Next Payment On: </Title>
                                 </TitleWrapper>
                                 <ValueWrapper>
                                    {paidThrough ? (
                                       <Value>{paidThrough}</Value>
                                    ) : (
                                       <Value>-</Value>
                                    )}
                                    {nextDate ? (
                                       <Value>{nextDate}</Value>
                                    ) : (
                                       <Value>-</Value>
                                    )}
                                 </ValueWrapper>
                              </CardWrapper>
                              <BottomLink>
                                 <LinkText
                                    pointer
                                    onClick={() => addStripeDetails()}
                                 >
                                    Modify Billing Details
                                 </LinkText>
                              </BottomLink>
                              <ButtonWrapper>Purchase New Device</ButtonWrapper>
                           </PanelBody>
                        </PanelCard>
                        */}
                     {/* </SubContainer> */}
                     <DeviceContainer>
                        <CardHeader>
                           <LeftWrapper>
                              <CardTitle>Device Issues</CardTitle>
                           </LeftWrapper>
                        </CardHeader>
                        <TableResponsive>
                           <CardTable>
                              <CardTableThead>
                                 <CardTr>
                                    <CardTh>Serial Number #</CardTh>
                                    <CardTh>Model</CardTh>
                                    <CardTh>Software Version</CardTh>
                                    <CardTh>Asset Tag</CardTh>
                                    <CardTh>Last used</CardTh>
                                    <CardTh>Assignee</CardTh>
                                    <CardTh>Issue</CardTh>
                                 </CardTr>
                              </CardTableThead>
                              <CardTableBody>
                                 {devicesDetail &&
                                    devicesDetail.map((item: any, index) => {
                                       const deviceUserName =
                                          item.user?.firstName ||
                                          item.user?.firstName
                                             ? `${item.user?.firstName}` +
                                               " " +
                                               `${item.user?.lastName}`
                                             : "";
                                       const deviceUserImage =
                                          item.user?.photoUrl &&
                                          item.user?.photoUrl;
                                       return (
                                          <CardTr key={index}>
                                             <CardTd>
                                                {item.serialNumber}
                                             </CardTd>
                                             <CardTd>
                                                {item.deviceModel?.name}
                                             </CardTd>
                                             <CardTd>
                                                {" "}
                                                {item.softwareVersion && (
                                                   <span className="version greenColor"></span>
                                                )}
                                                {item.softwareVersion}
                                             </CardTd>
                                             <CardTd>{item.assetId}</CardTd>
                                             <CardTd>
                                                {item?.lastUsedAt &&
                                                   moment(
                                                      parseInt(
                                                         item?.lastUsedAt,
                                                         10
                                                      )
                                                   ).format(
                                                      "MMM DD, YYYY (h:mm A)"
                                                   )}
                                             </CardTd>
                                             <CardTd>
                                                {deviceUserImage && (
                                                   <span className="assignIcon">
                                                      <AssignImage
                                                         src={
                                                            deviceUserImage ||
                                                            noImg
                                                         }
                                                      />
                                                   </span>
                                                )}
                                                {deviceUserName}
                                             </CardTd>
                                             <CardTd>Not Assigned</CardTd>
                                          </CardTr>
                                       );
                                    })}
                              </CardTableBody>
                           </CardTable>
                           {devicesDetail?.length === 0 && !isDataLoading ? (
                              <NoContentText>No Records Found </NoContentText>
                           ) : (
                              false
                           )}
                        </TableResponsive>
                     </DeviceContainer>
                  </Container>
               )}
            </MainWrapper>
         ) : (
            <ErrorImage src={errorImg} />
         )}
      </>
   );
};
export default Home;
